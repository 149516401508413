import styled from '@emotion/styled';
import { memo, useCallback, useEffect, useState } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { Chip, Select } from '@mantine/core';
import { FcCalendar } from 'react-icons/fc';
import { useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { css } from '@emotion/react';
import { useVirtualAccountOrderPre } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { EmptyScreenerDataView } from '~/pages/yaya168_stock/ScreenerContent/EmptyScreenerDataView';
import { store } from '../../heineken_template/_private/store';
import { useRealTimeResource } from '~/modules/screener/containers/useStockScreenerResource';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
//輸入api的agent和virtualAccountCode，就可以取得即時或歷史的選股資料
export const ScreenerContent = memo(function ScreenerContent(props) {
    const { realTimeApiAgent, virtualAccountCode } = props;
    const tradedDate = useSnapshot(staticStore).tradedDate;
    const day0 = tradedDate.day0;
    const intraday = tradedDate.intraday.format('YYYY-MM-DD');
    const historyDate = tradedDate.days.map(s => s.format('YYYY-MM-DD'));
    const [screenerType, setScreenerType] = useState('realTime');
    const [screenerDate, setScreenerDate] = useState(intraday);
    // 即時選股資料
    const realTimeSymbol = useRealTimeResource({ agent: realTimeApiAgent, date: intraday }).data ?? [];
    // 歷史的選股資料
    const orderLongEntry = useVirtualAccountOrderPre(virtualAccountCode, 'B', {
        dateTime: screenerDate,
    })?.map(a => a.symbol);
    const [symbols, setSymbols] = useState(realTimeSymbol);
    // 訂閱即時資料
    useEffect(() => {
        signalrStore2.addQuote(symbols);
        return () => {
            signalrStore2.removeQuote(symbols);
        };
    }, [JSON.stringify(symbols)]);
    // 切換即時/歷史頁面，給予對應的symbol
    useEffect(() => {
        if (screenerType === 'realTime') {
            setSymbols(realTimeSymbol);
        }
        else {
            setSymbols(orderLongEntry ?? []);
        }
    }, [JSON.stringify(orderLongEntry), screenerType, JSON.stringify(realTimeSymbol)]);
    //每5分拿一次最新交易日
    useEffect(() => {
        const intervalId = setInterval(() => {
            tradedDate;
            day0;
        }, 5 * 1000 * 60);
        return () => clearInterval(intervalId);
    }, [tradedDate, day0]);
    // 在選擇即時/歷史，給予選項框對應的選股日期
    const screenerDateTimeData = useCallback(() => (screenerType === 'history' ? historyDate : [intraday]), [screenerType, intraday, JSON.stringify(historyDate)]);
    const screenerDateData = screenerDateTimeData();
    //載入動畫 ------------------------------------------------
    const loadComplete = symbols?.length !== 0;
    return (<styleds.container>
      <styleds.selecter>
        <Chip css={css `
            .mantine-hwb60 {
              width: 72px;
              height: 28px;
              background-color: #252931;
            }
          `} checked={screenerType === 'realTime'} onChange={() => {
            // 改變日期就會改變api取得的資料，就會啟動useEffect自動更新symbol
            setScreenerDate(intraday);
            //切換即時/歷史頁籤
            setScreenerType('realTime');
        }} size={'sm'} radius='md' color='#448899'>
          即時
        </Chip>
        <Chip css={css `
            .mantine-hwb60 {
              width: 72px;
              height: 28px;
              background-color: #252931;
            }
          `} checked={screenerType === 'history'} onChange={() => {
            // 改變日期就會改變api取得的資料，就會啟動useEffect自動更新symbol
            setScreenerDate(screenerDateData[0]);
            //切換即時/歷史頁籤
            setScreenerType('history');
        }} size={'sm'} radius='md' color='#448899'>
          歷史
        </Chip>

        <Select css={css `
            .mantine-58jpum {
              width: 160px;
              background-color: #292c33;
            }
          `} disabled={screenerType === 'realTime' ? true : false} icon={<FcCalendar size='24px'/>} defaultValue={screenerDateData[0]} value={screenerDate} data={screenerDateData.slice(0, 15)} onChange={(date) => {
            // 改變日期就會改變api取得的資料，就會啟動useEffect自動更新symbol
            setScreenerDate(date);
        }}/>
      </styleds.selecter>

      <styleds.body>
        {loadComplete && (<SimpleQuoteListInteractWithChart data={symbols ?? []} chart={store.charting}/>)}
        {!loadComplete && <EmptyScreenerDataView />}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    position: relative;
    ${fill_vertical_cross_center};
    gap: 4px;
    min-width: 316px;
  `,
    selecter: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    width: 100%;
    height: 36px;
    gap: 4px;
  `,
    body: styled.div `
    position: relative;
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: 100%;
    gap: 2px;
  `,
};
